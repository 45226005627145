import { computed, Injectable, signal } from '@angular/core';
import { LocalStorageService } from '@client-portal/services/localstorage';
import { Division, DivisionCode } from '@client-portal/models/common';
import { objectKeys } from '@client-portal/functions/utils';
import { SelectOption } from '@client-portal/ui/select';
import { UserService } from '@client-portal/services/user';
import { toSignal } from '@angular/core/rxjs-interop';
import { FeatureFlagsService } from '@client-portal/services/feature-flags';
import { filter, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DivisionService {
  private _userProfile = toSignal(this._userService.userProfile$);
  private _divisions = signal({
    isSng: false,
    isTns: false,
  });

  public division = signal(
    this._localStorage.getItem('division') as DivisionCode,
  );
  public oppositeDivision = computed(
    () =>
      objectKeys(Division).find(
        (key) => key !== this.division(),
      ) as DivisionCode,
  );
  public divisionName = computed(() => Division[this.division()]);
  public hasMultipleDivisions = computed(() => {
    const { isSng, isTns } = this._divisions();
    return isSng && isTns;
  });

  public divisionSelectOptions: Array<SelectOption> = objectKeys(Division).map(
    (key) => ({
      value: key,
      viewValueArray: [Division[key]],
    }),
  );

  constructor(
    private _localStorage: LocalStorageService,
    private _userService: UserService,
    private _featureFlags: FeatureFlagsService,
  ) {
    this._init();
  }

  public setDivision(division: DivisionCode): void {
    this.division.set(division);
    this._localStorage.setItem('division', division);
    this._setDivisionFeatureFlags(division);
  }

  private _init(): void {
    this._userService.userProfile$
      .pipe(
        filter((profile) => !!profile),
        take(1),
      )
      .subscribe((profile) => {
        let division = this.division() as DivisionCode | null;
        this._divisions.set({
          isSng: profile.isSng,
          isTns: profile.isTns,
        });

        if (!division) {
          // if the user has access to multiple divisions, this will default to TNS
          switch (true) {
            case profile.isTns:
              division = 'TNS';
              break;
            case profile.isSng:
              division = 'SNG';
              break;
            default:
              throw Error('User does not have a division');
          }
        }
        this.setDivision(division);
      });
  }

  private _setDivisionFeatureFlags(division: DivisionCode): void {
    const profile = this._userProfile();
    if (!profile) return;

    switch (division) {
      case 'TNS':
        this._featureFlags.updateFeatureFlag(
          'shiftBookingType',
          profile.tnsShiftBookingType,
        );
        break;
      case 'SNG':
        this._featureFlags.updateFeatureFlag(
          'shiftBookingType',
          profile.sngShiftBookingType,
        );
        break;
    }
  }
}
